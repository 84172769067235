@use "global-color" as *;

$button-palettes: (
    "primary": $g-color-primary,
    "secondary": $g-color-secondary,
    "monochrome": $g-color-monochrome,
    "static-white": $g-color-monochrome,
    "static-black": $g-color-monochrome,
    "blue": $g-color-blue,
    "evergreen": $g-color-evergreen,
    "green": $g-color-green,
    "maroon": $g-color-maroon,
    "ocean": $g-color-ocean,
    "olive": $g-color-olive,
    "red-orange": $g-color-red-orange,
    "sand": $g-color-sand,
    "sky-blue": $g-color-sky-blue,
    "terracotta": $g-color-terracotta,
    "yellow-orange": $g-color-yellow-orange,
    "info": $g-color-blue,
    "alert": $g-color-red,
    "success": $g-color-green,
);

@mixin component-shared-tokens {
    --c-alert-banner-icon-stroke-width: 1.5px;
    --c-alert-banner-padding: var(--g-spacing-md);
    --c-alert-banner-success-text-color: var(--s-color-success-foreground);
    --c-alert-banner-success-icon-color: var(--s-color-success-foreground);
    --c-alert-banner-info-text-color: var(--s-color-info-foreground);
    --c-alert-banner-info-icon-color: var(--s-color-info-foreground);
    --c-alert-banner-alert-text-color: var(--s-color-alert-foreground);
    --c-alert-banner-alert-icon-color: var(--s-color-alert-foreground);

    --c-alert-collapsible-alert-text-color: var(--s-color-alert-foreground);
    --c-alert-collapsible-info-text-color: var(--s-color-info-foreground);
    --c-alert-collapsible-success-text-color: var(--s-color-success-foreground);
    --c-alert-collapsible-icon-stroke-width: 1.5px;
    --c-alert-collapsible-content-inner-padding: var(--g-spacing-md);
    --c-alert-collapsible-trigger-border-radius-focus: var(
        --g-border-radius-sm
    );

    --c-badge-size: var(--g-size-xs);

    --c-breadcrumb-outline-border-radius-focus: var(--g-border-radius-xs);

    --c-button-primary-border-radius: 100rem;
    --c-button-primary-border-width: var(--g-border-width-xs);
    --c-button-primary-outline-offset-focus: var(--g-spacing-xxs);
    --c-button-primary-outline-width-focus: var(--g-border-width-sm);
    --c-button-primary-shadow-offset-default: 0 0 0;
    --c-button-primary-margin-offset: 0;
    --c-button-primary-shadow-offset-active: 0 0 0;
    --c-button-primary-shadow-offset-hover: 0 0 0;
    --c-button-primary-shadow-offset-focus: 0 0 0;
    --c-button-primary-shadow-offset-default-high-contrast: 0 0 0;
    --c-button-primary-shadow-offset-active-high-contrast: 0 0 0;
    --c-button-primary-shadow-offset-hover-high-contrast: 0 0 0;
    --c-button-primary-shadow-offset-focus-high-contrast: 0 0 0;
    --c-button-primary-transform-default: none;
    --c-button-primary-transform-active: scale(0.96);
    --c-button-primary-transform-hover: none;
    --c-button-primary-transform-focus: none;
    --c-button-primary-transform-default-high-contrast: none;
    --c-button-primary-transform-active-high-contrast: scale(0.96);
    --c-button-primary-transform-hover-high-contrast: none;
    --c-button-primary-transform-focus-high-contrast: none;

    --c-button-primary-small-icon-size: var(--g-size-xxs);
    --c-button-primary-standard-icon-size: var(--g-size-xxs);

    --c-button-secondary-border-radius: 100rem;
    --c-button-secondary-border-width: var(--g-border-width-xs);
    --c-button-secondary-outline-offset-focus: var(--g-spacing-xxs);
    --c-button-secondary-outline-width-focus: var(--g-border-width-sm);
    --c-button-secondary-transform-active: scale(0.96);

    --c-button-secondary-small-icon-size: var(--g-size-xxs);
    --c-button-secondary-standard-icon-size: var(--g-size-xxs);

    --c-button-quiet-border-radius: var(--g-border-radius-xs);
    --c-button-quiet-border-width: var(--g-border-width-sm);
    --c-button-quiet-outline-offset-focus: 0px;
    --c-button-quiet-outline-width-focus: var(--g-border-width-sm);
    --c-button-quiet-spacing-horizontal-focus: var(--g-spacing-xxs);
    --c-button-quiet-underline-width: var(--g-border-width-xs);

    --c-button-quiet-small-icon-size: var(--g-size-xxs);
    --c-button-quiet-small-underline-spacing: 1px;
    --c-button-quiet-standard-icon-size: var(--g-size-xxs);
    --c-button-quiet-standard-underline-spacing: var(--g-spacing-xxs);

    --c-button-inverse-border-radius: 100rem;
    --c-button-inverse-border-width: var(--g-border-width-xs);
    --c-button-inverse-outline-offset-focus: var(--g-spacing-xxs);
    --c-button-inverse-outline-width-focus: var(--g-border-width-sm);
    --c-button-inverse-transform-active: scale(0.96);

    --c-button-inverse-small-icon-size: var(--g-size-xxs);
    --c-button-inverse-standard-icon-size: var(--g-size-xxs);

    --c-card-padding-desktop: var(--g-spacing-xl);
    --c-card-padding: var(--g-spacing-md);
    --c-card-button-outline-width-focus: var(--g-border-width-sm);
    --c-card-button-padding-desktop: var(--g-spacing-xl);
    --c-card-button-padding: var(--g-spacing-md);

    --c-celebration-asset-max-height: 120px;
    --c-celebration-overlay-opacity: 0.6;
    --c-celebration-success-text-color: var(--s-color-success-foreground);
    --c-celebration-info-text-color: var(--s-color-info-foreground);

    --c-collapsible-trigger-border-radius-focus: var(--g-border-radius-sm);

    --c-icon-button-primary-standard-height: var(--g-size-md);
    --c-icon-button-primary-small-height: 36px;
    --c-icon-button-primary-border-width: var(--g-border-width-xs);
    --c-icon-button-primary-outline-offset-focus: 0;

    --c-icon-button-secondary-standard-height: var(--g-size-md);
    --c-icon-button-secondary-small-height: 36px;
    --c-icon-button-secondary-border-width: var(--g-border-width-xs);
    --c-icon-button-icon-stroke-width: 1.5px;

    --c-loading-skeleton-border-radius: var(--g-border-radius-sm);
    --c-loading-skeleton-color: #{$g-color-neutral-400};

    --c-menu-item-border-radius-focus: var(--g-border-radius-sm);

    --c-modal-footer-padding-vertical: var(--g-spacing-md);

    --c-progress-bar-bar-border-radius: 10rem;

    --c-check-button-border-radius: 100rem;
    --c-check-button-standard-icon-size: var(--g-size-xxs);
    --c-check-button-small-icon-size: var(--g-size-xxs);

    --c-rating-field-icon-color-disabled: var(--s-color-disabled-primary);
    --c-rating-field-button-border-radius-focus: var(--g-border-radius-sm);

    --c-search-field-border-radius: 10rem;
    --c-search-field-icon-stroke-width: 2px;
    --c-search-field-placeholder-text-font-style: italic;
    --c-search-field-standard-height: 44px;
    --c-search-field-standard-padding-right: var(--g-spacing-xs);
    --c-search-field-standard-separator-display: block;
    --c-search-field-standard-shadow: var(--s-shadow-standard-default);
    --c-search-field-quiet-height: 40px;
    --c-search-field-quiet-padding-right: var(--g-spacing-xs);
    --c-search-field-quiet-separator-display: block;
    --c-search-field-icon-button-outline-border-radius-focus: 10rem;

    --c-slider-item-gap: var(--g-spacing-md);

    --c-slider-button-standard-height: var(--g-size-md);
    --c-slider-button-standard-border-radius: 100%;
    --c-slider-button-standard-shadow-default: var(--s-shadow-standard-default);
    --c-slider-button-standard-icon-size: var(--g-size-xs);
    --c-slider-button-standard-icon-stroke-width: 1.5px;
    --c-slider-button-standard-shadow-active: var(--s-shadow-standard-active);
    --c-slider-button-standard-outline-offset-focus: 0px;

    --c-slider-button-quiet-height: var(--g-size-md);
    --c-slider-button-quiet-border-radius: 100%;
    --c-slider-button-quiet-icon-size: var(--g-size-xs);
    --c-slider-button-quiet-icon-stroke-width: 1.5px;

    --c-slider-button-quiet-inverse-height: 30px;
    --c-slider-button-quiet-inverse-border-radius: 1000px;
    --c-slider-button-quiet-inverse-icon-size: var(--g-size-xs);
    --c-slider-button-quiet-inverse-icon-stroke-width: 1.5px;

    --c-status-label-border-radius: 10rem;

    --c-task-label-border-radius: 10rem;

    --c-tooltip-arrow-stroke-width: var(--g-border-width-sm);
    --c-tooltip-border-color: var(--s-color-neutral-border);
    --c-tooltip-border-width: var(--g-border-width-xs);
    --c-tooltip-padding: var(--g-spacing-md);
    --c-tooltip-shadow: var(--s-shadow-standard-default);

    --c-markdown-quote-font-weight: var(--g-font-weight-bold);
    --c-markdown-quote-color-fallback: var(--s-color-brand-secondary-standard);
    --c-markdown-link-border-radius-focus: var(--g-border-radius-sm);

    --c-pagination-button-border-radius: var(--g-border-radius-sm);
    --c-pagination-button-spacing-horizontal: var(--g-spacing-sm);
    --c-pagination-number-border-radius: var(--g-border-radius-sm);

    --c-calendar-button-border-radius: 100%;
    --c-calendar-button-icon-stroke-width: 1.5;

    --c-radio-circle-top-padding: 2px;

    --c-tour-card-grid-image-width: 33%;

    --c-container-xs: 320px;
    --c-container-sm: 544px;
    --c-container-md: 736px;
    --c-container-lg: 960px;
    --c-container-xl: 1200px;
    --c-container-full: 100%;

    --c-header-main-nav-link-font-weight: var(--g-font-weight-regular);
    --c-header-main-nav-link-font-size: var(--g-font-size-400);
    --c-header-main-nav-link-letter-spacing: var(--g-letter-spacing-standard);
    --c-header-main-nav-link-text-case: none;
    --c-header-main-nav-icon-spacing-top: var(--g-spacing-xxs);
    --c-header-link-outline-border-radius-focus: var(--g-border-radius-xs);

    --c-filter-bar-border-radius: 100px;
    --c-filter-button-border-radius-focus: var(--g-border-radius-sm);

    --c-deals-tour-card-wrapper-border-radius: var(--g-border-radius-lg);

    --c-wishlist-button-shadow-default: var(--s-shadow-standard-default);
    --c-wishlist-button-shadow-active: var(--s-shadow-standard-active);

    --c-switcher-background: #{$g-color-primary-50};
    --c-switcher-background-border-radius: 100rem;
    --c-switcher-background-border-color: #{$g-color-blue-50};
    --c-switcher-button-border-radius: 100rem;
}
