@use "global-color" as *;

@mixin semantic-light-tokens {
    --s-color-text-primary: #{$g-color-neutral-900};
    --s-color-text-secondary: #{$g-color-neutral-600};
    --s-color-text-quiet: #{$g-color-neutral-500};
    --s-color-text-inverse: #{$g-color-neutral-10};

    --s-color-icon-primary: #{$g-color-neutral-900};
    --s-color-icon-secondary: #{$g-color-neutral-600};
    --s-color-icon-quiet: #{$g-color-neutral-500};
    --s-color-icon-inverse: #{$g-color-neutral-10};

    --s-color-surface-primary: #{$g-color-neutral-warm-50};
    --s-color-surface-secondary: #{$g-color-neutral-warm-10};
    --s-color-surface-quiet: #{$g-color-neutral-warm-100};

    --s-color-separator-standard: #{$g-color-neutral-200};
    --s-color-separator-bold: #{$g-color-neutral-900};

    --s-color-alert-standard: #{$g-color-red-500};
    --s-color-alert-background: #{$g-color-red-50};
    --s-color-alert-foreground: #{$g-color-red-500};
    --s-color-alert-border: #{$g-color-red-200};

    --s-color-info-standard: #{$g-color-blue-500};
    --s-color-info-background: #{$g-color-blue-50};
    --s-color-info-foreground: #{$g-color-blue-500};
    --s-color-info-border: #{$g-color-blue-200};

    --s-color-success-standard: #{$g-color-green-500};
    --s-color-success-background: #{$g-color-green-50};
    --s-color-success-foreground: #{$g-color-green-600};
    --s-color-success-border: #{$g-color-green-200};

    --s-color-field-border-default: #{$g-color-neutral-400};
    --s-color-field-border-active: #{$g-color-neutral-900};
    --s-color-field-border-focus: #{$g-color-neutral-900};
    --s-color-field-border-error: var(--s-color-alert-standard);

    --s-color-selection-background-default: var(--s-color-surface-secondary);
    --s-color-selection-background-active: #{$g-color-primary-50};
    --s-color-selection-background-selected-bold: #{$g-color-primary-500};
    --s-color-selection-background-selected-quiet: var(
        --s-color-surface-secondary
    );

    --s-color-selection-foreground-default: #{$g-color-primary-900};
    --s-color-selection-foreground-active: #{$g-color-primary-600};
    --s-color-selection-foreground-selected-bold: #{$g-color-primary-10};
    --s-color-selection-foreground-selected-quiet: #{$g-color-primary-600};

    --s-color-selection-border-focus: #{$g-color-primary-500};
    --s-color-selection-border-selected-bold: #{$g-color-primary-500};
    --s-color-selection-border-selected-quiet: #{$g-color-primary-500};

    --s-color-tab-background-default: #{$g-color-primary-50};
    --s-color-tab-background-active: var(--s-color-surface-secondary);
    --s-color-tab-background-selected: var(--s-color-surface-secondary);

    --s-color-tab-foreground-default: var(--s-color-text-secondary);
    --s-color-tab-foreground-active: #{$g-color-primary-500};
    --s-color-tab-foreground-selected: #{$g-color-primary-900};

    --s-color-tab-border-focus: #{$g-color-primary-500};
    --s-color-tab-border-selected: #{$g-color-primary-900};

    --s-color-scrollbar-default: #{$g-color-neutral-400};

    --s-color-neutral-standard: #{$g-color-neutral-600};
    --s-color-neutral-background: #{$g-color-neutral-100};
    --s-color-neutral-foreground: #{$g-color-neutral-600};
    --s-color-neutral-border: #{$g-color-neutral-300};

    --s-color-disabled-primary: #{$g-color-neutral-400};
    --s-color-disabled-secondary: #{$g-color-neutral-10};

    @media (forced-colors: active) {
        --s-color-disabled-primary: GrayText;
        --s-color-disabled-secondary: GrayText;
    }

    --s-color-dataviz-primary: #{$g-color-primary-600};
    --s-color-dataviz-secondary: #{$g-color-sky-blue-500};

    --s-color-promo1-background: #{$g-color-sky-blue-300};
    --s-color-promo1-text-large: #{$g-color-sky-blue-700};
    --s-color-promo1-text-standard: #{$g-color-sky-blue-800};
    --s-color-promo1-accent-background: #{$g-color-yellow-orange-100};
    --s-color-promo1-accent-foreground: #{$g-color-sky-blue-600};

    --s-color-promo2-background: #{$g-color-yellow-orange-100};
    --s-color-promo2-text-large: #{$g-color-red-orange-500};
    --s-color-promo2-text-standard: #{$g-color-red-orange-700};
    --s-color-promo2-accent-background: #{$g-color-sky-blue-300};
    --s-color-promo2-accent-foreground: #{$g-color-sky-blue-800};

    --s-color-promo3-background: #{$g-color-red-orange-500};
    --s-color-promo3-text-large: #{$g-color-taupe-100};
    --s-color-promo3-text-standard: #{$g-color-neutral-warm-50};
    --s-color-promo3-accent-background: #{$g-color-sky-blue-300};
    --s-color-promo3-accent-foreground: #{$g-color-sky-blue-800};

    --s-color-promo4-background: #{$g-color-taupe-100};
    --s-color-promo4-text-large: #{$g-color-red-orange-500};
    --s-color-promo4-text-standard: #{$g-color-red-orange-600};
    --s-color-promo4-accent-background: #{$g-color-maroon-600};
    --s-color-promo4-accent-foreground: #{$g-color-taupe-100};

    --s-color-promo-price-standard: #{$g-color-green-600};
    --s-color-promo-deal-standard: #{$g-color-green-600};
    --s-color-promo-deal-background: #{$g-color-green-500};
    --s-color-promo-deal-foreground: #{$g-color-green-10};
    --s-color-promo-deal-border: #{$g-color-green-400};

    --s-color-accent1-standard: #{$g-color-terracotta-500};
    --s-color-accent1-bold: #{$g-color-terracotta-600};
    --s-color-accent1-quiet: #{$g-color-terracotta-50};

    --s-color-accent2-standard: #{$g-color-ocean-500};
    --s-color-accent2-bold: #{$g-color-ocean-600};
    --s-color-accent2-quiet: #{$g-color-ocean-50};

    --s-color-accent3-standard: #{$g-color-olive-500};
    --s-color-accent3-bold: #{$g-color-olive-600};
    --s-color-accent3-quiet: #{$g-color-olive-50};

    --s-color-accent4-standard: #{$g-color-sand-500};
    --s-color-accent4-bold: #{$g-color-sand-600};
    --s-color-accent4-quiet: #{$g-color-sand-50};

    --s-color-brand-primary-standard: #{$g-color-primary-500};
    --s-color-brand-primary-bold: #{$g-color-primary-600};
    --s-color-brand-primary-quiet: #{$g-color-primary-50};

    --s-color-brand-secondary-standard: #{$g-color-secondary-500};
    --s-color-brand-secondary-bold: #{$g-color-secondary-600};
    --s-color-brand-secondary-quiet: #{$g-color-secondary-50};

    --s-color-brand-loyalty-level1: #7a6d58;
    --s-color-brand-loyalty-level2: #d12f1a;
    --s-color-brand-loyalty-level3: #527880;
    --s-color-brand-loyalty-level4: #966b0d;
    --s-color-brand-loyalty-level5: #4f6e5e;

    --s-color-brand-global-rewards-level1: #90501e;
    --s-color-brand-global-rewards-level2: #676567;
    --s-color-brand-global-rewards-level3: #856525;
    --s-color-brand-global-rewards-level4: #2a2c2d;
    --s-color-brand-global-rewards-level5: #224e73;

    --s-gradient-accent1-quiet: linear-gradient(
        180deg,
        #{rgba($g-color-terracotta-50, 1)} 0%,
        #{rgba($g-color-terracotta-50, 0)} 100%
    );
    --s-gradient-accent2-quiet: linear-gradient(
        180deg,
        #{rgba($g-color-ocean-50, 1)} 0%,
        #{rgba($g-color-ocean-50, 0)} 100%
    );
    --s-gradient-accent3-quiet: linear-gradient(
        180deg,
        #{rgba($g-color-olive-50, 1)} 0%,
        #{rgba($g-color-olive-50, 0)} 100%
    );
    --s-gradient-accent4-quiet: linear-gradient(
        180deg,
        #{rgba($g-color-sand-50, 1)} 0%,
        #{rgba($g-color-sand-50, 0)} 100%
    );
    --s-gradient-accent-surface-quiet: linear-gradient(
        180deg,
        #{rgba($g-color-taupe-50, 1)} 0%,
        #{rgba($g-color-taupe-50, 0)} 100%
    );

    --s-gradient-brand-global-rewards-level1: linear-gradient(
        180deg,
        #90501e 0%,
        #693a16 100%
    );
    --s-gradient-brand-global-rewards-level2: linear-gradient(
        180deg,
        #676567 0%,
        #403f40 100%
    );
    --s-gradient-brand-global-rewards-level3: linear-gradient(
        180deg,
        #856525 0%,
        #5e481a 100%
    );
    --s-gradient-brand-global-rewards-level4: linear-gradient(
        180deg,
        #2a2c2d 0%,
        #070708 100%
    );
    --s-gradient-brand-global-rewards-level5: linear-gradient(
        180deg,
        #224e73 0%,
        #17344d 100%
    );

    --s-gradient-dataviz-primary: linear-gradient(
        180deg,
        #{$g-color-primary-300} 0%,
        #{$g-color-primary-500} 100%
    );
    --s-gradient-dataviz-secondary: linear-gradient(
        180deg,
        #{$g-color-sky-blue-300} 0%,
        #{$g-color-sky-blue-500} 100%
    );
    --s-gradient-dataviz-primary-start: #{$g-color-primary-300};
    --s-gradient-dataviz-primary-end: #{$g-color-primary-500};

    --s-shadow-standard-default: 0 2px 10px #{rgba($g-color-black, 0.1)};
    --s-shadow-standard-active: 0 4px 12px #{rgba($g-color-black, 0.15)};
    --s-shadow-up: 0 -4px 8px #{rgba($g-color-black, 0.1)};
    --s-shadow-down: 0 4px 8px #{rgba($g-color-black, 0.1)};
}
